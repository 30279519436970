import $router from '@/router'

const state = {
	idpName: 'VHUB',
	idpClientApi: null,
	identityProviderConfig: {},
	identityProviderConnectStatus: 'none',
	idpAccount: {},
};
const getters = {
	getIdentityProviderName: function() {
		return state.idpName;
	},
	getActiveIdpAccount() {
		return state.idpAccount;
    }
};
const mutations = {
    setIdentityProviderConnectStatus(state, status) {
		state.identityProviderConnectStatus = status;
    },
    setIdentityProviderName(state, name) {
		state.idpName = name;
    },
    setActiveIdpAccount(state, account) {
		state.idpAccount = account;
    }
};
const actions = {
   
    /**
    * Standard secondary login method for idp,
    * after the user first entered the credentials,
    * needs to be implemented by all identity provider stores
    * */
    idpCheckLogin: async ({dispatch, commit}) => {
		// eslint-disable-next-line no-undef
		const authToken = $cookies.get('authToken');
		// eslint-disable-next-line no-undef
		if(authToken) {
			// eslint-disable-next-line no-undef
			commit('setAuthToken', {authToken});
			await dispatch('checkLogin', {authToken: authToken});
		}
		if($router.currentRoute.name === 'logout') {
			commit('setAuthToken', {authToken: null});
			commit('setLoading', false);
			await $router.push('/');
			commit('setActiveIdpAccount', {});
		}
		// eslint-disable-next-line no-undef
		else if(!$cookies.get('authToken')) {
			// await dispatch('checkLogin');
			commit('setLoading', false);
		}
    },
    
    /**
    * Standard primary login method for idp,
    * (click on login button)
    * needs to be implemented by all identity provider stores
    * */
    idpLogin: async ({dispatch}, args) => {
		const {username, password} = args;
		if(username === 'public') {
			throw new Error('errors.userPassOrNameIsWrong');
		}
		return dispatch('clientAuthenticateVHUB', {username, password, include: 'organizations,teams'})
                .then(user => {
                  if(!user.authToken) {
                    throw new Error(this.$t('errors.userPassOrNameIsWrong'));
                  }
                  dispatch('setAuthToken', user.authToken);
                  const ts = new Date();
                  ts.setTime(ts.getTime() + (1*60*60*1000))
                  const expirationDate = ts.toUTCString();
					// eslint-disable-next-line no-undef
                  $cookies.set('authToken', user.authToken, expirationDate);
                  dispatch('setCurrentUserId', user.id);
                  dispatch('setStateLogin');
                }).then(() => {
                  dispatch('checkLogin');
                }).catch(async e => {
                    return dispatch('loadClient')
                      .then(() => {
                        dispatch('doLogout');
                        throw new Error(e.message);
                    })
              });
    },
    
    /**
    * Standard logout method for idp
    * needs to be implemented by all identity provider stores
    * */
	// eslint-disable-next-line no-unused-vars
    idpLogout: ({dispatch}) => {
		dispatch('doLogout');
    },
    
    /**
	* Loads the client necessary to perform signin/signout actions
	* mandatory for all adapters
	* */
    loadIdentityProviderClient: async() => {
		return new Promise(resolve => {
			console.log('- vHUB identity provider loaded');
			resolve();
		})
    },
    /**
	* Refreshes token when it is about to be expired
	* mandatory for adapters (if not necessary, make an empty method)
	* */
    autoRefreshToken: () => {
		// not necessary for this adapter, but mandatory method
    }
};
export default {
    state,getters,mutations,actions
}
